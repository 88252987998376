import React, { useEffect } from "react";
import PropTypes from "@arc-fusion/prop-types";
import { useAppContext } from "fusion-context";
import useLiveEventContent from "~/shared-components/useLiveEvent";
import getAssembleUrlWithParams from "./utilities/url-with-params";

const getUrl = getAssembleUrlWithParams("");

const ForceUpdate = () => {
  /* eslint-disable-line */
  const { isAdmin } = useAppContext();
  const [content, ackContent] = useLiveEventContent("force-update");
  const updatedLiveTimestamp = (content && content.timestamp) || 0;

  const asyncUpdate = React.useCallback(async () => {
    if (updatedLiveTimestamp) {
      if (!isAdmin) {
        ackContent();
        window.location.replace(getUrl(new Date().getTime()));
      }
    }
  }, [ackContent, isAdmin, updatedLiveTimestamp]);

  useEffect(() => {
    asyncUpdate();

    return () => {};
  }, [asyncUpdate, updatedLiveTimestamp]);

  return <div data-id="force-update" />;
};

export const ForceUpdateFields = {
  customFields: PropTypes.shape({
    timestamp: PropTypes.dateTime
  })
};

ForceUpdate.props = ForceUpdateFields;
export default ForceUpdate;
